var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-container d-flex content align-items-center"},[_c('ul',{staticClass:"nav navbar-nav d-xl-none"},[_c('li',{staticClass:"nav-item pt-lg-1"},[_c('b-link',{staticClass:"nav-link",on:{"click":_vm.toggleVerticalMenuActive}},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"21"}})],1)],1)]),_c('b-navbar-nav',{staticClass:"nav align-items-center ml-auto"},[_c('Locale'),_c('b-nav-item-dropdown',{staticClass:"dropdown-user",attrs:{"right":"","toggle-class":"d-flex align-items-center dropdown-user-link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"bg-white py-50 px-1 d-sm-flex d-none align-items-center",staticStyle:{"border-radius":"10px"}},[_c('b-avatar',{staticClass:"bg-black mr-50",attrs:{"text":_vm.userData && _vm.userData.user_email && String(_vm.userData.user_email).charAt(0) || 'a'}}),_c('p',{staticClass:"user-name font-weight-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.userData.user_email)+" "),_c('svg',{attrs:{"width":"12","height":"7","viewBox":"0 0 12 7","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 7L0.803848 0.25H11.1962L6 7Z","fill":"#888888"}})])])],1)]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{
          name: 'user-profile',
          params: {
            table: 'user'
          }
        }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"UserIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('header~profile'))+" ")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{name: 'comming-soon'}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"MailIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('header~inbox'))+" ")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{
          name: 'table',
          params: {
            table: 'ticket'
          }
        }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"CheckSquareIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('header~tasks'))+" ")])],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":_vm.logout}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LogOutIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('header~logout'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }