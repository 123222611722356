<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item pt-lg-1">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <Notifications /> -->
      <Locale/>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div
            class="bg-white py-50 px-1 d-sm-flex d-none align-items-center"
            style="border-radius: 10px;"
          >
            <b-avatar
              class="bg-black mr-50"
              :text="userData && userData.user_email && String(userData.user_email).charAt(0) || 'a'"
            />
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.user_email }}
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 7L0.803848 0.25H11.1962L6 7Z"
                  fill="#888888"
                />
              </svg>
            </p>
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{
            name: 'user-profile',
            params: {
              table: 'user'
            }
          }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>
            {{ $t('header~profile') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{name: 'comming-soon'}"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>
            {{ $t('header~inbox') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{
            name: 'table',
            params: {
              table: 'ticket'
            }
          }"
        >
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>
            {{ $t('header~tasks') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-divider/>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>
            {{ $t('header~logout') }}
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
// import { initialAbility } from '@/libs/acl/config'
import Locale from './Locale.vue'
// import Notifications from './Notifications.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,
    // Notifications,
    // Navbar Components
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    async logout() {
      try {
        await useJwt.logout()
      } finally {
        // Redirect to login page
        await this.$router.push({ name: 'auth-login' })
      }
    },
  },
}
</script>
